import { useQuery } from 'hooks'
import { useMemo } from 'react'


type Input = {
  queryParam?: string
  fields?: string[]
  skip?: boolean
}

const useCityFastOptions = (props?: Input) => {
  const { fields, skip } = props || {}

  const options = useMemo(() => {
    const query = fields ? `&json=${JSON.stringify({ fields })}` : ''

    return {
      url: `/ajax.php?action=getCityFastOptions${query}`,
      skip,
    }
  }, [ skip, fields ])

  const { data, isFetching, errors } = useQuery<Settings.CityFastOptions>(options)

  return {
    cityFastOptions: data || {} as Settings.CityFastOptions,
    isCityFastOptionsFetching: isFetching,
    cityFastOptionsErrors: errors,
  }
}

export default useCityFastOptions