import React from 'react'
import cx from 'classnames'

import appstoreImage from './images/appstore.svg'
import androidImage from './images/android.svg'
import rustoreImage from './images/rustore.svg'
import appgalleryImage from './images/appgallery.svg'

import s from './MobileAppImage.scss'


type LogoBottomProps = {
  className?: string
  type: 'appstore' | 'googleplay' | 'rustore' | 'appgallery'
}

const logo = {
  appstore: appstoreImage,
  rustore: rustoreImage,
  googleplay: androidImage,
  appgallery: appgalleryImage,
}

const MobileAppImage: React.FC<LogoBottomProps> = (props) => {
  const { className, type } = props

  return (
    <img className={cx(s.image, className)} src={logo[type]} />
  )
}


export default MobileAppImage
